<template>
    <div style="height: calc(100vh - 92px)">
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || fetchingNewData || waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <table-sip-history @getSrc="getSrc" :historyList="history" />
            <modal-audio :audioSrc="audioSrc"></modal-audio>
        </div>
        <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    </div>
</template>
<script>
import tableSipHistory from "@/views/component/Table/tableSipHistory.vue"
import modalAudio from "@/views/component/Modal/ModalSIP/modalAudio.vue"

export default {
    components: {
        tableSipHistory,
        modalAudio
    },
    data() {
        return {
            history: [],
            audioSrc: null,
            showPreloader: true,
            fields: [
                { key: 'id', label: 'Id' },
                { key: 'unique', label: 'UID' },
                { key: 'disposition', label: 'Статус звонка' },
                { key: 'billsec', label: 'Время разговора' },
                { key: 'src', label: 'Входящие' },
                { key: 'dst', label: 'Исходящие' },
                { key: 'calldate', label: 'Дата звонка' },
                { key: 'lastdata', label: 'Конечная линия' },
                // { key: 'channel', label: 'Канал 1' },
                // { key: 'dstchannel', label: 'Канал 2' },
                { key: 'order_id', label: 'Id Заказа' },
                // { key: 'perfomer_id', label: 'ID пользователя' },
                { key: 'memo_id', label: 'ID СЗ' },
                { key: 'driver_profile_id', label: 'ID Анкеты' }
            ],
        }
    },
    mounted() {
        this.$store.commit('pageData/setdataCount', null)
        this.refresh()
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        },
        waiting_filter() {
            return this.$store.state.refresh.waiting_filter
        }
    },
    methods: {
        sendToParent(tableData) {
            this.history = tableData
            this.$store.commit('pageData/setdataCount', this.history.length)
        },
        getSrc(src){
            this.audioSrc = src
            setTimeout(this.$bvModal.show('audio'), 0);
        },
        refresh() {
            this.showPreloader = true
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            this.$http.get('asterisk-api/history-call', { params: param }).then(res => {
                this.history = res.data
                this.$store.commit('pageData/setdataCount', this.history.length)
                this.showPreloader = false
            })
            .catch((err) => {
                this.showPreloader = false
                console.log(err);
            })   
        },
        openFilter(){
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true) {
                this.showPreloader = true
                this.$http
                    .get(`${this.$route.name}`, {params: param}).then(res => {
                        this.cars = res.data
                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                    })
            }else if(arrayRoutes.includes(this.$route.path) == false){
                this.$bvModal.show(this.$route.name + 'filter')
            }
        },
    },
    watch: {
        fetchingNewData(val){
            if (val){
                this.$http	
                .get(`${this.$route.name}`)
                .then(res =>{
                    this.unfoundAddresses = res.data
                    this.$store.commit('REFRESH_DATA', false)
                })
            }
        }
    }
}
</script>
